const radius = 35
const stroke = 3
const normalizedRadius = radius - stroke * 2
const circumference = normalizedRadius * 2 * Math.PI

const RadialProgress = ({ steps, currentStep }) => {
  const progress = ((currentStep + 1) / steps.length) * 100

  return (
    <div className="flex flex-row items-center justify-center mt-[55px] md:hidden">
      <div className="inline-flex items-center justify-center overflow-hidden rounded-full ">
        <svg className="w-[70px] h-[70px] -rotate-90">
          <circle
            className="text-inactive-gray-button"
            strokeWidth="4"
            stroke="currentColor"
            fill="transparent"
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <circle
            className="text-white"
            strokeWidth={stroke}
            stroke="currentColor"
            fill="transparent"
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <circle
            className="text-sika-yellow"
            strokeWidth={stroke}
            strokeDasharray={circumference}
            strokeDashoffset={circumference - (progress / 100) * circumference}
            strokeLinecap="square"
            stroke="currentColor"
            fill="transparent"
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>
        <p className="absolute text-sm text-black">
          {currentStep + 1} of {steps.length}
        </p>
      </div>
      <div className="text-sm text-black ml-[4px]">
        {steps[currentStep]?.stepType || ''}
      </div>
    </div>
  )
}

export default RadialProgress
