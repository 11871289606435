export const mocks = [
  {
    stepType: 'Type of Roof',
    stepTitle: 'Select a roof type',
    items: [
      {
        title: 'Full Green Roof System',
        source: 'productImages/1-full-green.svg',
        type: 'fullGreen',
        categories: [],
      },
      {
        title: 'Extensive Green Roof',
        source: 'productImages/1-extensive-green.svg',
        type: 'extensive',
        categories: [],
      },
      {
        title: 'Biodiversive green roof',
        source: 'productImages/1-biodiversive-green.svg',
        type: 'biodiversive',
        categories: [],
      },
    ],
  },
  {
    stepType: 'Base Material',
    stepTitle: 'Select the type of the base material',
    items: [
      {
        title: 'Concrete deck with Sika admixtures',
        source: 'productImages/2-concrete-base.svg',
        type: 'concrete',
        categories: [
          'Full Green Roof System',
          'Extensive Green Roof',
          'Biodiversive green roof',
        ],
      },
      {
        title: 'Trapezoidal metal sheet',
        source: 'productImages/2-metal-sheet-base.svg',
        type: 'metalSheet',
        categories: [
          'Full Green Roof System',
          'Extensive Green Roof',
          'Biodiversive green roof',
        ],
      },
    ],
  },
  {
    stepType: 'Protection Fleece',
    stepTitle: 'Select the type of the protection fleece',
    items: [
      // {
      //   title: 'S-Vap 3000 E SA',
      //   subtitle: 'Sell-adhesive vapour barrier and carrier membrane',
      //   source:
      //     'https://s3-alpha-sig.figma.com/img/7a91/fcef/b1d4ef4bdd89c0555d0b79c70b18f018?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=h8PWpO7~ZCzdPjcQ-0lWifXRn6js1nU6nDgp~YFM0ZQDu05bbVV2btA6hIH~sW3gegQRQxhvtJpz45gjn8-TXx75iZEKPZF1UJrF1dbQQWx9k~pyuh0jZoEBOhsgbRVVNQr7-RNDarAW-6d3MKAEhtmy6AudmbNXuoApaUmZTsg1GP8HrP1AFacIWcm487-01NgUjF5IuVFwc-DIOWeu2SXYSyGvY3JtRuS8gk9Q570OS5fm9j0EL6xqDwufcGOpEtKEVQRspp2rjpZTdfHeJX42a09KlwMdxDpN1AuSUpvAla9HUadHk5jKtM1pfDX5sjknxMKPZZf~3qzo1WmaWQ__',
      //   categories: ['Full Green Roof System', 'Concrete deck with Sika admixtures', 'Trapezoidal metal sheet']
      // },
      // {
      //   title: 'S-Vap 5000 E SA',
      //   subtitle: " Polyethylene based vapour control layer",
      //   source:
      //     'https://s3-alpha-sig.figma.com/img/7a91/fcef/b1d4ef4bdd89c0555d0b79c70b18f018?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=h8PWpO7~ZCzdPjcQ-0lWifXRn6js1nU6nDgp~YFM0ZQDu05bbVV2btA6hIH~sW3gegQRQxhvtJpz45gjn8-TXx75iZEKPZF1UJrF1dbQQWx9k~pyuh0jZoEBOhsgbRVVNQr7-RNDarAW-6d3MKAEhtmy6AudmbNXuoApaUmZTsg1GP8HrP1AFacIWcm487-01NgUjF5IuVFwc-DIOWeu2SXYSyGvY3JtRuS8gk9Q570OS5fm9j0EL6xqDwufcGOpEtKEVQRspp2rjpZTdfHeJX42a09KlwMdxDpN1AuSUpvAla9HUadHk5jKtM1pfDX5sjknxMKPZZf~3qzo1WmaWQ__',
      //   categories: ['Full Green Roof System', 'Concrete deck with Sika admixtures', 'Trapezoidal metal sheet']
      // },
      // {
      //   title: 'Sarnavap®-1000 E',
      //   subtitle: 'Vapour control layer',
      //   source:
      //     'https://s3-alpha-sig.figma.com/img/7a91/fcef/b1d4ef4bdd89c0555d0b79c70b18f018?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=h8PWpO7~ZCzdPjcQ-0lWifXRn6js1nU6nDgp~YFM0ZQDu05bbVV2btA6hIH~sW3gegQRQxhvtJpz45gjn8-TXx75iZEKPZF1UJrF1dbQQWx9k~pyuh0jZoEBOhsgbRVVNQr7-RNDarAW-6d3MKAEhtmy6AudmbNXuoApaUmZTsg1GP8HrP1AFacIWcm487-01NgUjF5IuVFwc-DIOWeu2SXYSyGvY3JtRuS8gk9Q570OS5fm9j0EL6xqDwufcGOpEtKEVQRspp2rjpZTdfHeJX42a09KlwMdxDpN1AuSUpvAla9HUadHk5jKtM1pfDX5sjknxMKPZZf~3qzo1WmaWQ__',
      //   categories: ['Full Green Roof System', 'Concrete deck with Sika admixtures', 'Trapezoidal metal sheet']
      // },
      // {
      //   title: 'Sarnavap®-2000 E',
      //   subtitle: 'Vapour control layer',
      //   source:
      //     'https://s3-alpha-sig.figma.com/img/7a91/fcef/b1d4ef4bdd89c0555d0b79c70b18f018?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=h8PWpO7~ZCzdPjcQ-0lWifXRn6js1nU6nDgp~YFM0ZQDu05bbVV2btA6hIH~sW3gegQRQxhvtJpz45gjn8-TXx75iZEKPZF1UJrF1dbQQWx9k~pyuh0jZoEBOhsgbRVVNQr7-RNDarAW-6d3MKAEhtmy6AudmbNXuoApaUmZTsg1GP8HrP1AFacIWcm487-01NgUjF5IuVFwc-DIOWeu2SXYSyGvY3JtRuS8gk9Q570OS5fm9j0EL6xqDwufcGOpEtKEVQRspp2rjpZTdfHeJX42a09KlwMdxDpN1AuSUpvAla9HUadHk5jKtM1pfDX5sjknxMKPZZf~3qzo1WmaWQ__',
      //   categories: ['Full Green Roof System', 'Concrete deck with Sika admixtures', 'Trapezoidal metal sheet']
      // },
      {
        title: 'SikaRoof Protection Fleece P-225UK',
        subtitle: 'Polypropylane protection fleece',
        source: 'productImages/3-p225-protection-sikaroof.svg',
        categories: [
          'Full Green Roof System',
          'Extensive Green Roof',
          'Biodiversive green roof',
          'Concrete deck with Sika admixtures',
          'Trapezoidal metal sheet',
        ],
      },
      {
        title: 'Sikalastic® Rapid Fleece-110',
        subtitle:
          'Synthetic-fibre fleece for Sikalastic® Rapid waterproofing resins',
        source: 'productImages/3-110-protection-sikalastic.svg',
        categories: [
          'Full Green Roof System',
          'Extensive Green Roof',
          'Biodiversive green roof',
          'Concrete deck with Sika admixtures',
          'Trapezoidal metal sheet',
        ],
      },
    ],
  },
  {
    stepType: 'Drainage system',
    stepTitle:
      'Select the type of the geocomposite drainage and protection layer',
    items: [
      {
        title: 'SikaRoof Rigid Drainage Layer 20 UK',
        subtitle: '20mm DRAINAGE BOARD WITH FLEECE',
        source: 'productImages/4-drainage-layer20-sikaroof.svg',
        categories: [
          'Full Green Roof System',
          'Extensive Green Roof',
          'Biodiversive green roof',
          'Concrete deck with Sika admixtures',
          'Trapezoidal metal sheet',
          'SikaRoof Protection Fleece P-225UK',
          'Sikalastic® Rapid Fleece-110',
        ],
      },
      {
        title: 'SikaRoof® Drainage Layer 40 UK',
        subtitle: 'PERFORATED 40mm DRAINAGE BOARD',
        source: 'productImages/4-drainage-layer40-sikaroof.svg',
        categories: [
          'Biodiversive green roof',
          'Concrete deck with Sika admixtures',
          'Trapezoidal metal sheet',
          'SikaRoof Protection Fleece P-225UK',
          'Sikalastic® Rapid Fleece-110',
        ],
      },
      {
        title: 'SikaRoof® Drainage Layer 60 UK',
        subtitle: 'PERFORATED 60mm DRAINAGE BOARD',
        source: 'productImages/4-drainage-layer60-sikaroof.svg',
        categories: [
          'Biodiversive green roof',
          'Concrete deck with Sika admixtures',
          'Trapezoidal metal sheet',
          'SikaRoof Protection Fleece P-225UK',
          'Sikalastic® Rapid Fleece-110',
        ],
      },
    ],
  },
  {
    stepType: 'Growing medium',
    stepTitle: 'Select the type of the vegetation blanket',
    items: [
      {
        title: 'SikaRoof® Substrate Extensive UK',
        subtitle: 'Growing medium made from 100% recycled material',
        source: 'productImages/5-substrate-extensive-uk.svg',
        categories: [
          'Full Green Roof System',
          'Extensive Green Roof',
          'Biodiversive green roof',
          'Concrete deck with Sika admixtures',
          'Trapezoidal metal sheet',
          'SikaRoof Protection Fleece P-225UK',
          'Sikalastic® Rapid Fleece-110',
          'SikaRoof Rigid Drainage Layer 20 UK',
        ],
      },
      {
        title: 'SikaRoof® Substrate Bio-diverse UK',
        subtitle: 'Growing medium made from 100% recycled material',
        source: 'productImages/5-substrate-biodiverse-uk.svg',
        categories: [
          'Biodiversive green roof',
          'Concrete deck with Sika admixtures',
          'Trapezoidal metal sheet',
          'SikaRoof Protection Fleece P-225UK',
          'Sikalastic® Rapid Fleece-110',
          'SikaRoof Rigid Drainage Layer 20 UK',
          'SikaRoof® Drainage Layer 40 UK',
          'SikaRoof® Drainage Layer 60 UK',
        ],
      },
      {
        title: 'SikaRoof® Substrate Intensive UK',
        subtitle: 'A nutrient-balanced and moisture retentive growing medium',
        source: 'productImages/5-substrate-intesive-uk.svg',
        categories: [
          'Full Green Roof System',
          'Extensive Green Roof',
          'Biodiversive green roof',
          'Concrete deck with Sika admixtures',
          'Trapezoidal metal sheet',
          'SikaRoof Protection Fleece P-225UK',
          'Sikalastic® Rapid Fleece-110',
          'SikaRoof Rigid Drainage Layer 20 UK',
          'SikaRoof® Drainage Layer 40 UK',
          'SikaRoof® Drainage Layer 60 UK',
        ],
      },
    ],
  },
  {
    stepType: 'Vegetation Blanket',
    stepTitle: 'Select the type of the vegetation blanket',
    items: [
      {
        title: 'SikaRoof® Sedum Blanket UK',
        subtitle: 'UK grown vegetation blanket for use with Green Roofs',
        source: 'productImages/6-sedum-blanket-uk.svg',
        categories: [
          'Full Green Roof System',
          'Extensive Green Roof',
          'Biodiversive green roof',
          'Concrete deck with Sika admixtures',
          'Trapezoidal metal sheet',
          'SikaRoof Protection Fleece P-225UK',
          'Sikalastic® Rapid Fleece-110',
          'SikaRoof Rigid Drainage Layer 20 UK',
          'SikaRoof® Drainage Layer 40 UK',
          'SikaRoof® Drainage Layer 60 UK',
          'SikaRoof® Substrate Extensive UK',
          'SikaRoof® Substrate Bio-diverse UK',
          'SikaRoof® Substrate Intensive UK',
        ],
      },
      {
        title: 'SikaRoof® Wildflower Blanket UK',
        subtitle: 'UK grown vegetation blanket for use with Green Roofs',
        source: 'productImages/6-wildflower-blanket-uk.svg',
        categories: [
          'Full Green Roof System',
          'Extensive Green Roof',
          'Biodiversive green roof',
          'Concrete deck with Sika admixtures',
          'Trapezoidal metal sheet',
          'SikaRoof Protection Fleece P-225UK',
          'Sikalastic® Rapid Fleece-110',
          'SikaRoof Rigid Drainage Layer 20 UK',
          'SikaRoof® Drainage Layer 40 UK',
          'SikaRoof® Drainage Layer 60 UK',
          'SikaRoof® Substrate Extensive UK',
          'SikaRoof® Substrate Bio-diverse UK',
          'SikaRoof® Substrate Intensive UK',
        ],
      },
    ],
  },
  {
    stepType: 'Sedum plugs',
    stepTitle: 'Select the type of the pre-cultivated sedum plugs',
    items: [
      {
        title: 'SikaRoof® Wildflower Seed UK',
        subtitle: 'Wildflower seed mix for use with bio-diverse green roofs',
        source: 'productImages/7-sedum-plugs-uk.svg',
        categories: [
          'Full Green Roof System',
          'Extensive Green Roof',
          'Biodiversive green roof',
          'Concrete deck with Sika admixtures',
          'Trapezoidal metal sheet',
          'SikaRoof Protection Fleece P-225UK',
          'Sikalastic® Rapid Fleece-110',
          'SikaRoof Rigid Drainage Layer 20 UK',
          'SikaRoof® Drainage Layer 40 UK',
          'SikaRoof® Drainage Layer 60 UK',
          'SikaRoof® Substrate Extensive UK',
          'SikaRoof® Substrate Bio-diverse UK',
          'SikaRoof® Substrate Intensive UK',
          'SikaRoof® Sedum Blanket UK',
          'SikaRoof® Wildflower Blanket UK',
        ],
      },
      {
        title: 'SikaRoof® Sedum Plugs UK',
        subtitle:
          'UK grown, pre-cultivated sedum plugs for use with Green Roofs',
        source: 'productImages/7-wildflower-seed-uk.svg',
        categories: [
          'Full Green Roof System',
          'Extensive Green Roof',
          'Biodiversive green roof',
          'Concrete deck with Sika admixtures',
          'Trapezoidal metal sheet',
          'SikaRoof Protection Fleece P-225UK',
          'Sikalastic® Rapid Fleece-110',
          'SikaRoof Rigid Drainage Layer 20 UK',
          'SikaRoof® Drainage Layer 40 UK',
          'SikaRoof® Drainage Layer 60 UK',
          'SikaRoof® Substrate Extensive UK',
          'SikaRoof® Substrate Bio-diverse UK',
          'SikaRoof® Substrate Intensive UK',
          'SikaRoof® Sedum Blanket UK',
          'SikaRoof® Wildflower Blanket UK',
        ],
      },
    ],
  },
  // {
  //   stepType: "Filtering sheets",
  //   stepTitle: "Select the type of the filtering sheets",
  //   items: [
  //     {
  //       title: 'SikaRoof® Filter Fleece F-120 UK',
  //       subtitle: "GEOTEXTILE FILTER FLEECE",
  //       source:
  //         'https://s3-alpha-sig.figma.com/img/7a91/fcef/b1d4ef4bdd89c0555d0b79c70b18f018?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=h8PWpO7~ZCzdPjcQ-0lWifXRn6js1nU6nDgp~YFM0ZQDu05bbVV2btA6hIH~sW3gegQRQxhvtJpz45gjn8-TXx75iZEKPZF1UJrF1dbQQWx9k~pyuh0jZoEBOhsgbRVVNQr7-RNDarAW-6d3MKAEhtmy6AudmbNXuoApaUmZTsg1GP8HrP1AFacIWcm487-01NgUjF5IuVFwc-DIOWeu2SXYSyGvY3JtRuS8gk9Q570OS5fm9j0EL6xqDwufcGOpEtKEVQRspp2rjpZTdfHeJX42a09KlwMdxDpN1AuSUpvAla9HUadHk5jKtM1pfDX5sjknxMKPZZf~3qzo1WmaWQ__',
  //     },
  //   ]
  // },
  {
    stepType: 'Summary',
    stepTitle: 'Detailed summary for you product',
    items: [
      {
        title: 'Res1',
        subtitle: 'Res1',
        source: '/productImages/result1.svg',
        categories: [
          'Full Green Roof System',
          'Extensive Green Roof',
          'Biodiversive green roof',
          'Concrete deck with Sika admixtures',
          'Trapezoidal metal sheet',
          'SikaRoof Protection Fleece P-225UK',
          'SikaRoof Rigid Drainage Layer 20 UK',
          'SikaRoof® Drainage Layer 40 UK',
          'SikaRoof® Drainage Layer 60 UK',
          'SikaRoof® Substrate Extensive UK',
          'SikaRoof® Sedum Blanket UK',
          'SikaRoof® Sedum Plugs UK',
        ],
      },
      {
        title: 'Res2',
        subtitle: 'Res2',
        source: '/productImages/result2.svg',
        categories: [
          'Biodiversive green roof',
          'Concrete deck with Sika admixtures',
          'Trapezoidal metal sheet',
          'SikaRoof Protection Fleece P-225UK',
          'SikaRoof Rigid Drainage Layer 20 UK',
          'SikaRoof® Substrate Bio-diverse UK',
          'SikaRoof® Wildflower Blanket UK',
          'SikaRoof® Wildflower Seed UK',
        ],
      },
      // {
      //   title: 'Res3',
      //   subtitle: 'Res3',
      //   source:
      //     'https://s3-alpha-sig.figma.com/img/9b43/cee4/d3fa5f99066e8d29f7a31e681c2f8ed7?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=kPz28LlzsyjBptc6HfhQll3o0YPhJCw9Vn0BCl6AlNKbY63dpX1NduFYdGGFXCnefdDwTpnXOG6WCjkoCxV5rj-jaXrpvQ2MMnMndIwKIhVHyHL68RoCglvbMMU~NAY~1X9NeDBRotIwxgbbYpMdIl5zOvBNQi4c0E2XcOu3If5JZQ3to4MrKy3fsXx3zxi7tX6Z58gcvuXaP8RGfX8hQpCpTWkrcQppq8lJZYBGaiUHlnp0wI0mWntROoWi3WgaYXxc69pMukJdD1998T47ZbPsti2XR1QyPyHec9c02Ip3rEZ0dkCdZAPpHpUH6VNbB8dUGbcdun7OWE5~r3GrqA__',
      //   categories: [
      //     'Full Green Roof System',
      //     'Extensive Green Roof',
      //     'Biodiversive green roof',
      //     'Concrete deck with Sika admixtures',
      //     'Trapezoidal metal sheet',
      //     'SikaRoof Protection Fleece P-225UK',
      //     'Sikalastic® Rapid Fleece-110',
      //     'SikaRoof Rigid Drainage Layer 20 UK',
      //     'SikaRoof® Drainage Layer 40 UK',
      //     'SikaRoof® Drainage Layer 60 UK',
      //     'SikaRoof® Substrate Bio-diverse UK',
      //     'SikaRoof® Substrate Extensive UK',
      //     'SikaRoof® Substrate Intensive UK',
      //     'SikaRoof® Sedum Blanket UK',
      //     'SikaRoof® Wildflower Blanket UK',
      //     'SikaRoof® Sedum Plugs UK',
      //     'SikaRoof® Wildflower Seed UK',
      //   ],
      // },
    ],
  },
]
