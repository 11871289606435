import { Navigation } from '../Navigation'

const Header = () => {
  return (
    <div className="relative">
      <div className="flex bg-sika-yellow h-[60px] md:h-[136px] justify-between items-center pr-[15px] md:pr-[52px]">
        <div className="flex flex-row text-lg h-full items-center">
          <img
            alt="header logo"
            src="/svg/sikaLogo.svg"
            className="object-contain h-full"
          />
          Sika
        </div>
        <div className="flex md:hidden flex-row">
          <img alt="menu" src="/Menu.svg" className="p-3" />
          <img alt="menu" src="/Search.svg" className="p-3" />
        </div>
        <input
          defaultValue="Search"
          className="hidden md:flex md:w-[196px] text-lg border-b-2 border-b-black py-1 bg-sika-yellow focus:outline-none"
        />
      </div>
      <Navigation />
    </div>
  )
}

export default Header
