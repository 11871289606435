import { Header } from '../components/Header'
import { Footer } from '../components/Footer'

const Main = ({ children }) => {
  return (
    <div className="w-full text-gray-700 antialiased">
      <Header />
      <div>
        <main className="flex flex-col items-center content text-base bg-content-gray xl:px-40">
          <div className="mt-12 lg:mt-24 mb-8 text-3xl font-semibold">
            Roof systems configurator
          </div>
          {children}
        </main>
        <Footer />
      </div>
    </div>
  )
}

export { Main }
