const ButtonGroup = ({ stepType, currentStep, handleOnClick }) => {
  if (stepType === 'Summary') {
    return (
      <div className="flex flex-col md:flex-row justify-center space-y-8 md:space-x-8 md:space-y-0 my-4">
        <button
          className={`${
            currentStep === 0 ? 'text-inactive-gray-button' : ''
          } py-3 px-16 border-2`}
          onClick={() => handleOnClick('startOver')}
        >
          Start Over
        </button>
        <button
          className="py-3 px-16 bg-sika-yellow"
          onClick={() => handleOnClick('increase')}
        >
          Get quotation
        </button>
      </div>
    )
  }

  return (
    <div className="sticky bottom-0 z-20 lg:relative flex flex-row justify-center bg-white space-x-8 my-4 w-full py-[20px] b-t-[1px] border-inactive-gray-button">
      <button
        className={`${
          currentStep === 0 ? 'bg-white text-inactive-gray-button' : ''
        } py-3 px-16 border-2`}
        onClick={() => handleOnClick('decrease')}
      >
        Back
      </button>
      <button
        className="py-3 px-16 bg-sika-yellow"
        onClick={() => handleOnClick('increase')}
      >
        Next
      </button>
    </div>
  )
}

export default ButtonGroup
