const Stepper = ({ steps, currentStep }) => {
  return (
    <ul className="steps pt-14 hidden md:flex">
      {steps.map((step, index) => (
        <li
          key={`${index}-${step.stepType}`}
          data-content={
            index < currentStep ? '✓' : index === currentStep ? '●' : ''
          }
          className={`step before:!h-[1px] w-full text-sm ${index < currentStep ? 'step-primary step-warning' : ''} `}
        >
          {step.stepType}
        </li>
      ))}
    </ul>
  )
}

export default Stepper
