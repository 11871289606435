import { useState } from 'react'
import Items from './Items'
import { ButtonGroup } from '../ButtonGroup'

const ConfiguratorItems = ({
  items,
  sectionTitle,
  changeStep,
  currentStep,
  stepType,
}) => {
  const [selectedItem, setSelectedItem] = useState()
  const [selectedChoicesList, setSelectedChoicesList] = useState([])

  const displayedItems = items.filter((item) => {
    return !!selectedChoicesList.every((choice) =>
      item.categories.includes(choice),
    )
  })

  const gridCol = {
    1: 'grid-cols-1 md:grid-cols-1',
    2: 'xl:grid-cols-2 md:grid-cols-2',
    3: 'xl:grid-cols-3',
  }
  const backToTop = () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  function handleOnClick(actionType) {
    // Will need refactoring - ugly solution for selectedChoicesList and selectedItem state management
    if (actionType === 'decrease') {
      backToTop()
      const newList = [...selectedChoicesList]
      newList.pop()

      setSelectedChoicesList(newList)
      changeStep(actionType, selectedChoicesList[currentStep - 1])
      setSelectedItem(null)
    } else if (actionType === 'startOver') {
      backToTop()
      changeStep(actionType)
      setSelectedChoicesList([])
      setSelectedItem(null)
    } else if (selectedItem && !selectedChoicesList[currentStep]) {
      backToTop()
      setSelectedChoicesList((prevState) => [...prevState, selectedItem])
      changeStep(actionType, selectedItem)
      setSelectedItem(null)
    } else if (!selectedItem) {
      changeStep(actionType, selectedChoicesList[currentStep])
    } else if (selectedChoicesList[currentStep]) {
      backToTop()
      const newList = [...selectedChoicesList]
      newList[currentStep] = selectedItem
      setSelectedChoicesList(newList)

      changeStep(actionType, selectedChoicesList[currentStep])
      setSelectedItem(null)
    }
  }

  return (
    <div>
      <div className="mt-6 xl:mt-8 text-base font-semibold">{sectionTitle}</div>
      <div
        className={`grid ${
          displayedItems.length < 3
            ? `grid-cols-1  ${gridCol[displayedItems.length]}`
            : 'xl:grid-cols-3 md:grid-cols-2'
        } justify-items-center mt-8`}
      >
        <Items
          itemList={displayedItems}
          selectedChoicesList={selectedChoicesList}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
          currentStep={currentStep}
          stepType={stepType}
        />
      </div>
      <ButtonGroup
        stepType={stepType}
        currentStep={currentStep}
        handleOnClick={handleOnClick}
      />
    </div>
  )
}

export default ConfiguratorItems
