import { useState } from 'react'

import { ConfiguratorItems } from '../ConfiguratorItems'
import { Stepper } from '../Stepper'
import { RadialProgress } from '../RadialProgress'

import { mocks } from '../ConfiguratorItems/mocks'

const Configurator = () => {
  const data = mocks
  const [step, setStep] = useState(0)

  function handleStep(handleType, selectedItem) {
    if (handleType === 'increase' && step < data.length - 1 && selectedItem) {
      setStep(step + 1)
    } else if (handleType === 'decrease' && step > 0) {
      setStep(step - 1)
    } else if (handleType === 'startOver'){
      setStep(0)
    }
  }

  return (
    <div className="flex flex-col bg-white px-10 lg:max-w-[1106px] items-center">
      <Stepper steps={data} currentStep={step} />
      <RadialProgress steps={data} currentStep={step} />
      <ConfiguratorItems
        items={data[step].items}
        sectionTitle={data[step].stepTitle}
        stepType={data[step].stepType}
        changeStep={handleStep}
        currentStep={step}
      />
    </div>
  )
}

export default Configurator
