const Navigation = () => {
  const navItems = [
    'Roof Solutions',
    'Flat Roof Products',
    'Knowledge & Resources',
    'Projects',
    'About us',
    'Roofing Support',
    'Contact Us',
  ]

  return (
    <div className="hidden lg:flex w-full justify-center bg-none absolute bottom-[-39px] ">
      <ul className="flex flex-row bg-white px-2 py-3 shadow-xl divide-x">
        {navItems.map((navItem, index) => (
          <li key={`${index}-${navItem}`} className="px-4 py-4 text-sm">
            {navItem}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Navigation
