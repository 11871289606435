import { Main } from '../templates/Main'
import { Configurator } from "../components/Configurator";

const ConfiguratorPage = () => {
  return (
    <Main>
      <Configurator/>
    </Main>
  )
}

export { ConfiguratorPage }