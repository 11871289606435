const Items = ({
  itemList,
  selectedChoicesList,
  setSelectedItem,
  selectedItem,
  currentStep,
  stepType,
}) => {
  const highlightedItem = selectedItem
    ? selectedItem
    : selectedChoicesList[currentStep]

  if (!itemList.length && stepType === 'Summary') {
    return (
      <div className={'grid grid-cols-1 md:grid-cols-2 md:mx-[130px] gap-x-10'}>
        <img src="/productImages/default.svg" alt="summary default" />
        <ul className="flex flex-col items-start">
          {selectedChoicesList.slice(2).map((choice, index) => (
            <li
              key={`${index}-${choice}`}
              style={{ listStyleType: 'square' }}
              className="my-1"
            >
              {choice}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return itemList.map((item) =>
    stepType !== 'Summary' ? (
      <button
        className="h-fit bg-base-100 shadow-xl m-4 max-w-[310px] [&>div]:hover:bg-sika-yellow"
        onClick={() => setSelectedItem(item.title)}
      >
        <figure className="w-full">
          <img src={item.source} className="w-full" alt={`${item.title}`} />
        </figure>
        <div
          className={`items-center px-8 bg-gray-200 hover:bg-sika-yellow ${
            highlightedItem === item.title ? 'bg-sika-yellow' : ''
          }`}
        >
          <p className="py-3 text-base font-semibold">{item.title}</p>
          {item.subtitle ? (
            <p className="pb-3 text-sm font-normal">{item.subtitle}</p>
          ) : null}
        </div>
      </button>
    ) : (
      <div className={'grid grid-cols-1 md:grid-cols-2 md:mx-[130px] gap-x-10'}>
        <img src={item.source} alt="summary" />
        <ul className="flex flex-col items-start">
          {selectedChoicesList.slice(2).map((choice, index) => (
            <li
              key={`${index}-${choice}`}
              style={{ listStyleType: 'square' }}
              className="my-1"
            >
              {choice}
            </li>
          ))}
        </ul>
      </div>
    ),
  )
}

export default Items
