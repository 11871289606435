const Footer = () => {
  return (
    <div className="text-white bg-footer-gray">
      <div
        id="main-footer"
        className="max-w-[1200px] flex flex-col bg-footer-gray text-left pt-[20px] pb-[60px] px-[30px] gap-[20px] justify-center md:pt-[40px] md:justify-between md:m-auto md:px-[60px] lg:flex-row"
      >
        <div className="flex flex-col md:flex-row md:gap-[30px] ">
          <div className="flex flex-col">
            <h4 className="w-fit text-xl border-b-[3px] border-white pb-[15px] mb-[20px] md:text-3xl">
              Sika Ltd
            </h4>
            <ul className="[&>li]:text-md [&>li]:pb-[16px]">
              <li>
                <a href="/#">Who are we?</a>
              </li>
              <li>
                <a href="/#">Careers</a>
              </li>
              <li>
                <a href="/#">Media Releases</a>
              </li>
              <li>
                <a href="/#">Sustainability</a>
              </li>
              <li>
                <a href="/#">COVID-19</a>
              </li>
            </ul>
          </div>
          <div>
            <ul className="[&>li]:text-md [&>li]:pb-[16px]">
              <h4 className="w-fit text-xl border-b-[3px] border-white pb-[15px] mb-[20px] md:text-3xl">
                Solutions
              </h4>
              <li>
                <a href="/#">Construction</a>
              </li>
              <li>
                <a href="/#">Industrial Manufacturing</a>
              </li>
              <li>
                <a href="/#">Distribution</a>
              </li>
              <li>
                <a className="flex gap-[2px]" href="/#">
                  Automotive
                  <img src="/svg/external.svg" alt="external" />
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="w-fit text-xl border-b-[3px] border-white pb-[15px] mb-[20px] md:text-3xl">
              Follow Us
            </h4>
            <ul className="flex flex-row gap-[14px] mb-[16px]">
              <li>
                <a href="/#">
                  <img src="/svg/facebook.svg" alt="facebook" />
                </a>
              </li>
              <li>
                <a href="/#">
                  <img src="/svg/youtube.svg" alt="youtube" />
                </a>
              </li>
              <li>
                <a href="/#">
                  <img src="/svg/linkedin.svg" alt="linkedin" />
                </a>
              </li>
              <li>
                <a href="/#">
                  <img src="/svg/instagram.svg" alt="instagram" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div>
          <h4 className="text-xl mb-[8px] md:text-3xl">Sika Limited</h4>

          <p className="mb-[16px]">
            Watchmead <br /> AL7 1BQ Welwyn Garden City <br /> <br /> Tel.:
            01707 394
            <br />
            <br />
            Sika House <br /> Preston
            <br />
            PR1 1EA
            <br />
            <br />
            Tel.: 01772 259 781
          </p>
        </div>
      </div>
      <div id="footer_links" className="bg-footer-nav-gray py-[20px] px-[20px]">
        <ul className="max-w-[1200px] flex flex-col flex-wrap [&>li]:py-[3px] [&>li>a]:px-[16px] md:m-auto md:flex-row md:px-[40px]">
          <li className="md:after:content-['|']">
            <a href="/#">Imprint</a>
          </li>
          <li className="md:after:content-['|']">
            <a href="/#">Legal Notice</a>
          </li>
          <li className="md:after:content-['|']">
            <a href="/#">Privacy Notice </a>
          </li>
          <li className="md:after:content-['|']">
            <a href="/#">Cookie Preference Center</a>
          </li>
          <li>
            <a href="/#" className="flex justify-center gap-[2px]">
              Exercise Your Privacy Rights
              <img src="/svg/external.svg" alt="external" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Footer
